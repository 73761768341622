import React from "react";
import "../../styles/chat-sidebar.css";
import ClassroomAI from "../../assets/logos/text-logo.svg";

function Sidebar({
                     handleSignOut,
                     handleNewChat,
                     handleLoadChat,
                     currentUser,
                     chatsHistory,
                     isSideBarVisible,
                     closeSideBar,
                     setIsReportShown,
                     showInfoPopup,
                     setShowInfoPopup,
                     isChatsLoading
                 }) {
    const handleClickFeedback = () => {
        const url = 'https://forms.gle/V7WZUzbN3bYUTZ248';
        window.open(url, '_blank');
    };

    return (
        <>
            <div
                className={`sideBar ${isSideBarVisible ? "visible" : ""}`}
            >
                {showInfoPopup && (
                    <div className="sidebar-popup">
                        <div className="upper">
                            <div className="popup-container" onClick={handleClickFeedback}>
                                <i className="fi fi-sr-comment-info action-icon"></i>
                                <span className="popup-text">გამოხმაურება</span>
                            </div>
                        </div>
                        <div className="popup-container last" onClick={handleSignOut}>
                            <i className="fi fi-sr-leave action-icon"></i>
                            <span className="popup-text">გასვლა</span>
                        </div>
                    </div>
                )}
                <div className="upperPartofSideBar" onClick={() => setShowInfoPopup(false)}>
                    <div className="upperPartTop">
                        <img src={ClassroomAI} alt="bar-icon" className="bar-icon"/>
                        <i className="fi fi-br-cross close" onClick={closeSideBar}></i>
                    </div>
                    <div className="top-buttons">
                        <button className="newchatBtn" onClick={handleNewChat}>
                            <span>ახალი ჩატი</span>
                        </button>
                    </div>

                    <div className="chats-container" onClick={closeSideBar}>
                        {isChatsLoading ? (
                            <div className="chats-skeleton-container">
                                <div className="chats-skeleton-text">
                                    <div className="chat-skeleton-line" style={{opacity: 0}}></div>
                                    <div className="chat-skeleton-line"
                                         style={{animationDelay: "200ms", opacity: 0}}></div>
                                    <div className="chat-skeleton-line"
                                         style={{animationDelay: "400ms", opacity: 0}}></div>
                                </div>
                            </div>
                        ) : (
                            <>
                                {Object.entries(chatsHistory).length === 0 ? (<div className="centered-container"><i
                                    className="fi fi-rr-time-past history-icon"></i></div>) : (<h3>ისტორია</h3>)}
                                {Object.entries(chatsHistory).reverse().map(([chatId, value]) => (
                                    <button
                                        key={chatId}
                                        className="chat-item"
                                        onClick={() => handleLoadChat(chatId)}
                                    >
                                        <i className="fi fi-ss-messages"></i>
                                        <span className="chat-title">&emsp;{Object.values(value)[0].content.geo}</span>
                                    </button>
                                ))}
                            </>
                        )}
                    </div>
                </div>
                <div
                    className="sidebar-footer-container"
                    onClick={() => setShowInfoPopup((prevClick) => !prevClick)}
                >
                    <img
                        src={currentUser.photoURL}
                        alt="user"
                        className="currentUserPhoto"
                    />
                    <p className="username">{currentUser.displayName}</p>
                </div>
            </div>
        </>
    );
}

export default Sidebar;
