import "../../styles/Loader.css";
import BubbleLogo from "../../assets/logos/logo-bubble.svg"

function ClassroomLoader() {
    return (
        <div className="classroom-loader-container">
            <div></div>
            <div className="logo-loader">
                <img src={BubbleLogo} alt="ClassroomAI Loader."/>
            </div>
            <h2>Powered By BTU</h2>
        </div>
    );
}

export default ClassroomLoader;
