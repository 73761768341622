import {database} from "../firebase";
import {get, ref, query, orderByKey, limitToLast} from "firebase/database";

export const getChatsHistory = async (userUid, numberOfChats = 6) => {
    const lastChatsRef = ref(database, `/chats/${userUid}`);
    const lastNChatsRef = query(lastChatsRef, orderByKey(), limitToLast(numberOfChats));

    const snapshot = await get(lastNChatsRef);

    if (snapshot.exists()) {
        return snapshot.val();
    } else {
        console.log("No data available");
        return {};
    }
};