import React from "react";
import "../../styles/report.css";

function Report({ isReportShown, setIsReportShown }) {

  const handleCloseReport = () => {
    setIsReportShown(false);
  };

  return (
      <div className={`report-popup ${isReportShown ? "" : "hide-container"}`}>
        <i
            className="fi fi-rr-circle-xmark report-close"
            onClick={handleCloseReport}
        ></i>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfen1kfvBuRQYDncOM-tJK4NP4PssFYZL8wa-y3BxXFbyUWOw/viewform?embedded=true" title="google-docs"
            width="640" height="607" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
        </iframe>
      </div>
  );
}

export default Report;
