const examText = [
  "შემიძლია თუ არა საგამოცდო დროის ცვლილება?",
  "როგორ შეიძლება აღვადგინო გაცდენილი გამოცდა?",
  "როგორ გავიდე დასკვნითი გამოცდის განმეორებით გამოცდაზე?",
];

const otherText = [
  "როგორ ხდება BTU CLASSROOM -დან განცხადების გადმოგზავნა?",
  "აქვს თუ არა BTU-ს გაცვლითი პროგრამები?",
  "როგორ გამოითვლება GPA?",
];

const tuitionText = [
  "როგორ გამოვთვალო 1 კრედიტის საფასური?",
  "როგორ გადავიხადო სწავლის საფასური?",
  "შესაძლებელია თუ არა რამდენიმე ეტაპად გადახდა?",
];

const processText = [
  "როდის ვნახავ ასარჩევი საგნების ჩამონათვალს?",
  "როდის დაიწყება არდადეგები?",
  "რა ვქნა შუალედურის განულების შემთხვევაში?",
];

const documentText = [
  "ცნობა მინდა სტუდენტობის შესახებ ქართულ-ინგლისურად",
  "ფორმა 26 საიდან შემიძლია გამოვიტანო?",
  "როგორ ავიღო ცნობა ინგლისური ენის დონის შესახებ?",
];

const allTexts = [
  { title: "გამოცდები", texts: examText },
  { title: "სხვადასხვა", texts: otherText },
  { title: "გადასახადები", texts: tuitionText },
  { title: "სასწავლო პროცესი", texts: processText },
  { title: "დოკუმენტების მოთხოვნა", texts: documentText },
];

export const shuffledTexts = allTexts.sort(() => Math.random() - 0.5);
