import React from "react";
import {UseAuth} from "../../contexts/AuthContext";
import Sidebar from "./Sidebar";
import {useState, useRef, useEffect} from "react";
import {toast} from "react-toastify";
import "../../styles/chat.css";
import Report from "../report/Report";
import Linkify from "react-linkify";
import BubbleLogo from "../../assets/logos/logo-bubble.svg"
import SUN from "../../assets/images/sun.svg";
import MOON from "../../assets/images/moon.svg";
import { getChatsHistory } from "../../services/db";
import { v4 as uuidv4 } from 'uuid';


function Chat() {
    const {currentUser, logOut} = UseAuth();

    const chatContainerRef = useRef(null);

    const [sendInput, setSendInput] = useState("");

    const [currentChatId, setCurrentChatId] = useState(null);

    const [buttons, setButtons] = useState([]);
    const [initialButtons, setInitialButtons] = useState([]);

    const [messages, setMessages] = useState([]);

    const [chatsHistory, setChatsHistory] = useState({});

    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isChatsLoading, setIsChatsLoading] = useState(false);

    const [isDark, setIsDark] = useState(false);

    const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);


    const [isSideBarVisible, setIsSideBarVisible] = useState(false);
    const [isReportShown, setIsReportShown] = useState(false);
    const [showInfoPopup, setShowInfoPopup] = useState(false);

    const [screenSize, setScreenSize] = useState(window.innerWidth);


    useEffect(() => {
        const fetchChatsHistory = async () => {
            setIsChatsLoading(true);
            try {
                const chatsData = await getChatsHistory(currentUser.uid);
                setChatsHistory(chatsData);
            } catch (error) {
                toast.error("ისტორიის ჩატვირთვისას დაფიქსირდა შეცდომა.");
                console.error('Error fetching chats:', error.message);
            } finally {
                setIsChatsLoading(false);
            }
        };

        fetchChatsHistory();
    }, [currentUser]);

    useEffect(() => {
        const handleWindowResize = () => {
            setScreenSize(window.innerWidth);
        };

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    useEffect(() => {
        const isDarkModeStored = localStorage.getItem("isDarkMode");

        if (isDarkModeStored) {
            setIsDark(isDarkModeStored === "true");
        }
    }, []);


    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop =
                chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        const getInitialButtons = async () => {
            try {
                const data = await initChat();
                setInitialButtons(data.buttons || []);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        getInitialButtons().then((r) => {
        });
    }, [currentUser]);

    // const handleMessageRate = async (messageId, newRate = null) => {
    //     try {
    //         const url = `${process.env.REACT_APP_BOT_API_BASE_URL}/message/rate`;
    //         const token = await currentUser.getIdToken();
    //         const response = await fetch(url, {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${token}`,
    //             },
    //             body: JSON.stringify({
    //                 messageId: messageId,
    //                 ChatId: currentChatId,
    //                 rate: newRate,
    //             }),
    //         });
    //         if (!response.ok) {
    //             toast.error("შეფასებისას დაფიქსირდა შეცდომა, მოგვიანებით სცადეთ");
    //             console.error("Error sending like/dislike:", response);
    //         } else {
    //             const updatedItems = messages.map((message) =>
    //                 message.messageId === messageId
    //                     ? {
    //                         ...message,
    //                         rate: newRate,
    //                     }
    //                     : message
    //             );

    //             setMessages(updatedItems);
    //         }
    //     } catch (error) {
    //         toast.error("შეფასებისას დაფიქსირდა შეცდომა, მოგვიანებით სცადეთ");
    //     }
    // };

    const initChat = async () => {
        try {
            setIsInitialLoading(true);
            const url = `${process.env.REACT_APP_BOT_API_BASE_URL}/chat/init`;
            const token = await currentUser.getIdToken();
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({}),
            });
            const initChatResponse = await response.json();
            setButtons(initChatResponse.buttons);
            addBotMessage(initChatResponse.message, initChatResponse.messageId);
            return initChatResponse;
        } catch (error) {
            addBotMessage({
                geo: "ბოდიში მეგობარო, ახლა თავს ცუდად ვგრძნობ მერე მომწერე 🥹",
                eng: "",
            }, "ERROR", "", "ERROR");
            setButtons({error: "პრობლემის შეტყობინება"});
            return {buttons: [], message: {}};
        } finally {
            setIsInitialLoading(false);
        }
    };

    const sendMessage = async (userMessage) => {
        const url = `${process.env.REACT_APP_BOT_API_BASE_URL}/bot/get-answer`;
        const token = await currentUser.getIdToken();
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    email: currentUser.email,
                    chatId: currentChatId,
                    message: userMessage,
                }),
            });

            const messageData = await response.json();
            if (!currentChatId) {
                const newChatId = messageData.chatId;
                setCurrentChatId(newChatId);
                setChatsHistory(prevChats => ({
                    ...prevChats,
                    [newChatId]: {[messageData.messageId]: {content: {geo: userMessage}}},
                }));
            }
            if (messageData.message) {
                addBotMessage(
                    messageData.message,
                    messageData.messageId,
                    messageData.rate,
                    messageData.provider
                );
            } else {
                addBotMessage({geo: "სერვერის შეცდომა. ☹️"});
            }

            if (Object.keys(messageData.buttons).length !== 0) {
                setButtons(messageData.buttons);
            }

        } catch (error) {
            addBotMessage({
                geo: "ისევ ცუდად გავხდი, მოგვიანებით მომწერე თუ შეგიძლია ☹️",
            });
            setButtons({error: "პრობლემის შეტყობინება"});
            console.error(error);
        }
    };

    const handleButtonClick = async (buttonText, buttonId) => {
        setIsButtonsDisabled(true);

        if (buttonId === "error") {
            setIsReportShown(true);
            setIsButtonsDisabled(false);
            setIsLoading(false);
            return;
        }
        const userMessage = {role: "user", content: {geo: buttonText}};
        setMessages([...messages, userMessage]);
        setIsLoading(true);
        setSendInput("");

        try {
            const token = await currentUser.getIdToken();
            const response = await fetch(
                `${process.env.REACT_APP_BOT_API_BASE_URL}/bot/get-button`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        chatId: currentChatId,
                        buttonId: buttonId,
                    }),
                }
            );

            if (!response.ok) {
                addBotMessage({
                    geo: "ისევ ცუდად გავხდი, მოგვიანებით მომწერეთ თუ შეიძლება ან სცადეთ გვერდის გადატვირთვა ☹️",
                });
                setButtons({error: "პრობლემის შეტყობინება"});
                console.error(response);
                return;
            }

            const buttonsData = await response.json();

            if (!currentChatId) {
                const newChatId = buttonsData.chatId;
                setCurrentChatId(newChatId);
                setChatsHistory(prevChats => ({
                    ...prevChats,
                    [newChatId]: {[buttonsData.messageId]: {content: {geo: buttonText}}},
                }));
            }

            addBotMessage(
                buttonsData.message,
                buttonsData.messageId,
                buttonsData.rate,
                buttonsData.provider
            );

            const buttons = buttonsData.buttons;

            if (Object.keys(buttons).length === 0) {
                setButtons((prevButtons) => {
                    const updatedButtons = {...prevButtons};
                    delete updatedButtons[buttonId];
                    if (Object.keys(updatedButtons).length === 0) {
                        setButtons(initialButtons);
                    } else {
                        setButtons(updatedButtons);
                    }

                    return updatedButtons;
                });
            } else {
                setButtons(buttons);
            }
        } catch (error) {
            addBotMessage({
                geo: "ისევ ცუდად გავხდი, მოგვიანებით მომწერე თუ შეგიძლია☹️ ან სცადე გვერდის გადატვირთვა",
            });
            setButtons({error: "პრობლემის შეტყობინება"});
            console.error(error);
        } finally {
            setIsButtonsDisabled(false);
        }
    };

    const handleSendMessage = async () => {
        if (sendInput.trim().length > 0) {
            setSendInput("");
            addUserMessage({geo: sendInput});

            try {
                await sendMessage(sendInput);
            } catch (error) {
                console.error(error);
            } 
            
            // finally {
            //     if (buttons && Object.keys(buttons).length > 0) {
            //         setButtons(buttons);
            //     }
            // }
        }
    };

    const handleNewChat = async () => {
        if (currentChatId) {
            setMessages([]);
            setIsLoading(false);
            setCurrentChatId(null);
            setIsSideBarVisible(false);
            await initChat();
        }
    };

    const handleSignOut = async () => {
        try {
            localStorage.clear();
            await logOut();
        } catch (error) {
            toast.error("Error signing out: Something went wrong");
        }
    };

    const addUserMessage = (content) => {
        const userMessage = {role: "user", content: content};
        if(chatsHistory.hasOwnProperty(currentChatId)){
            const updatedChatsHistory = {...chatsHistory};
            updatedChatsHistory[currentChatId][uuidv4()] = userMessage;
            setChatsHistory(updatedChatsHistory);
        }

        setMessages((prevChat) => [...prevChat, userMessage]);
        setIsLoading(true);
    };

    const addBotMessage = (content, messageId, rate = "", provider = "") => {
        const botResponse = {
            role: "assistant",
            content: content,
            messageId: messageId,
            rate: rate,
            provider: provider
        };

        if(chatsHistory.hasOwnProperty(currentChatId)){
            const updatedChatsHistory = {...chatsHistory};
            updatedChatsHistory[currentChatId][messageId] = botResponse;
            setChatsHistory(updatedChatsHistory);
        }


        setMessages((prevChat) => [...prevChat, botResponse]);
        setIsLoading(false);
    };

    const handleEnterPress = (e) => {
        if (e.key === "Enter" && !isLoading) {
            handleSendMessage().then(() => {
            });
        }
    };

    const handleLoadChat = (chatId) => {
        setIsInitialLoading(true);
        if (chatId !== currentChatId) {
            setCurrentChatId(chatId);
            let formattedMessages = Object.keys(chatsHistory[chatId]).map(messageId => ({
                messageId: messageId,
                ...chatsHistory[chatId][messageId]
            }));
            setMessages(Object.values(formattedMessages))
        }
        setIsInitialLoading(false);
    };

    const handleInputChange = (event) => {
        const userInput = event.target.value;

        const georgianPattern = /^[\u10A0-\u10FF0-9\s?!.,:'"]*$/;

        if (georgianPattern.test(userInput)) {
            setSendInput(userInput);
        }
    };

    const handleReportShown = () => {
        setIsReportShown(true);
        setShowInfoPopup(false);
    };

    const toggleDarkMode = () => {
        const newIsDark = !isDark;
        setIsDark(newIsDark);
        localStorage.setItem("isDarkMode", newIsDark.toString());
    };

    const showSideBar = () => {
        setIsSideBarVisible(true);
    };

    const closeSideBar = () => {
        setIsSideBarVisible(false);
    };

    return (
        <div className={`container ${isDark ? "dark" : ""} `}>
            <Sidebar
                handleSignOut={handleSignOut}
                handleNewChat={handleNewChat}
                handleLoadChat={handleLoadChat}
                currentUser={currentUser}
                isInitialLoading={isInitialLoading}
                chatsHistory={chatsHistory}
                isSideBarVisible={isSideBarVisible}
                setIsSideBarVisible={setIsSideBarVisible}
                closeSideBar={closeSideBar}
                toggleDarkMode={toggleDarkMode}
                isDark={isDark}
                isReportShown={isReportShown}
                handleReportShown={handleReportShown}
                setIsReportShown={setIsReportShown}
                showInfoPopup={showInfoPopup}
                setShowInfoPopup={setShowInfoPopup}
                isChatsLoading={isChatsLoading}
            />
            <Report
                isReportShown={isReportShown}
                setIsReportShown={setIsReportShown}
                currentUser={currentUser}
            />
            <div
                className={`main ${isReportShown ? "blur" : ""}`}
                onClick={() => setShowInfoPopup(false)}
            >
                <i
                    className={`fi fi-br-menu-burger burgerMenu hidden ${
                        isDark ? "text-white" : ""
                    }`}
                    onClick={showSideBar}
                ></i>

                <label className="theme-toggle-container">
                    <input
                        className="theme-toggle-checkbox"
                        type="checkbox"
                        checked={!isDark}
                        onChange={toggleDarkMode}
                    ></input>
                    <div className="theme-toggle-slot">
                        <div className="sun-icon-wrapper">
                            <img src={SUN} alt="sun"/>
                        </div>
                        <div className="theme-toggle-button"></div>
                        <div className="moon-icon-wrapper">
                            <img src={MOON} alt="moon"/>
                        </div>
                    </div>
                </label>
                <div
                    onClick={closeSideBar}
                    className="chats"
                    ref={chatContainerRef}
                >
                    {isInitialLoading ? (
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    ) : (
                        <>
                            <div>
                                {messages?.map((message, index) => (

                                    <div key={`${index}-${message.messageId}-${message.rate}`}
                                         className={`chat-message ${message.role === "user" ? "user" : `bot `}`}>
                                        <div
                                            className={`chat-message-content ${isDark ? "darkBackground" : "lightBackground"}`}>
                                            <img src={message.role === "user" ? currentUser.photoURL : BubbleLogo}
                                                 alt="userPhoto" className="avatar"/>

                                            <p className={`text ${isDark ? "text-white" : ""}`}>
                                                <Linkify
                                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                                        <a target="_blank" href={decoratedHref} rel="noreferrer"
                                                           key={key}>
                                                            {decoratedText}
                                                        </a>
                                                    )}
                                                    properties={{target: '_blank'}}
                                                >
                                                    {message?.content?.geo
                                                        .replace(/\\n/g, '\n')
                                                        .replaceAll('btu . edu . ge','btu.edu.ge')
                                                        .replaceAll('https : //', 'https://')
                                                        .replaceAll('https: //', 'https://')
                                                    }

                                                </Linkify>
                                            </p>
                                        </div>
                                        <div className="chat-message-footer">
                                            {message?.provider && <span
                                                className="chat-message-footer provider">{message.provider}</span>}
                                        </div>
                                    </div>
                                ))}

                                {isLoading &&
                                    (<div className={`chat-message bot`}>
                                            <div
                                                className={`chat-message-content ${isDark ? "darkBackground" : "lightBackground"}`}>
                                                <img src={BubbleLogo} className="avatar" alt="avatar"/>
                                                <div id="wave">
                                                    <span
                                                        className={`dot ${
                                                            isDark ? "dotLightColor" : "dotGrayColor"
                                                        }`}
                                                    ></span>
                                                    <span
                                                        className={`dot ${
                                                            isDark ? "dotLightColor" : "dotGrayColor"
                                                        }`}
                                                    ></span>
                                                    <span
                                                        className={`dot ${
                                                            isDark ? "dotLightColor" : "dotGrayColor"
                                                        }`}
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                            <div></div>
                        </>
                    )}
                </div>
                <div className="chatFooter" onClick={closeSideBar}>
                    {buttons && Object.keys(buttons).length > 0 && (
                        <div className="content-buttons-container">
                            {Object.entries(buttons).map(([buttonId, buttonText]) => (
                                <button
                                    key={buttonId}
                                    onClick={() => handleButtonClick(buttonText, buttonId)}
                                    className={`content-button ${
                                        isDark ? "dark-button" : "light-button"
                                    }`}
                                    disabled={isButtonsDisabled || isLoading}
                                >
                                    {buttonText}
                                </button>
                            ))}
                        </div>
                    )}
                    <div className={`send-input-container ${isDark ? "darkInput" : ""}`}>
                        <input
                            type="text"
                            placeholder="მომწერე ქართულად..."
                            value={sendInput}
                            onKeyDown={handleEnterPress}
                            onChange={handleInputChange}
                            className={`send-input ${
                                isDark ? "lightInput send-input-gray-placeholder" : ""
                            }`}
                        />
                        <button
                            className={`send ${isLoading ? "" : "sendColor"}`}
                            onClick={handleSendMessage}
                            disabled={isLoading}
                        >
                            <i className="fi fi-ss-paper-plane"></i>
                        </button>
                    </div>
                    {screenSize > 1024 ? (
                        <p className={`${isDark ? "white-footer-text" : "black-footer-text"}`}>
                            ბოტმა შესაძლოა დააგენერიროს თქვენ მიერ დასმული კითხვის
                            კონტექსტიდან განსხვავებული/შეუსაბამო პასუხები
                        </p>
                    ) : (
                        <p className={`${isDark ? "white-footer-text" : "black-footer-text"}`}>
                            ბოტმა შესაძლოა დააგენერიროს კონტექსტიდან შეუსაბამო პასუხები
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Chat;
