import React from "react";
import "../styles/App.css";
import Login from "./auth/Login";
import {Route, Routes} from "react-router-dom";
import Chat from "./chat/Chat";
import {AuthContextProvider} from "../contexts/AuthContext";
import Protected from "../components/Protected";
import 'react-toastify/dist/ReactToastify.css';
import "@flaticon/flaticon-uicons/css/all/all.css";
import {Toaster} from "react-hot-toast";

function App() {
    return (<div>
        <AuthContextProvider>
            <Routes>
                <Route path="/" element={<Login/>}/>
                <Route
                    path="chat"
                    element={<Protected>
                        <Chat/>
                    </Protected>}
                />
            </Routes>
            <div><Toaster/></div>
        </AuthContextProvider>
    </div>);
}

export default App;
