import {createContext, useContext, useEffect, useState} from "react";
import {GoogleAuthProvider, signInWithRedirect, signOut, onAuthStateChanged} from "firebase/auth";
import {auth} from "../firebase";
import ClassroomLoader from "../pages/loader";

const AuthContext = createContext(null);

export const UseAuth = () => {
    return useContext(AuthContext);
};

export const AuthContextProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [authLoading, setAuthLoading] = useState(true);

    const googleSignIn = async () => {
        setAuthLoading(true);
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({prompt: "select_account"});
        await signInWithRedirect(auth, provider).then(() => {
        })
    };

    const logOut = async () => {
        signOut(auth).then(() => {
        });
    };


    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setAuthLoading(false);
        });

        return () => unsubscribe();
    }, []);


    const value = {currentUser, googleSignIn, logOut}

    return (<AuthContext.Provider value={value}>
        {authLoading ? (<ClassroomLoader></ClassroomLoader>) : (children)}
    </AuthContext.Provider>);
};


