import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import "../../styles/Login.css";
import {UseAuth} from "../../contexts/AuthContext";
import {getRedirectResult} from "firebase/auth";
import {auth} from "../../firebase";

import {TypeAnimation} from "react-type-animation";
import {shuffledTexts} from "../../loginpagedata/loginData";
import btuLogoWhite from "../../assets/logos/text-logo.svg";
import {toast} from "react-hot-toast";

function Login() {

    const {currentUser, googleSignIn} = UseAuth();

    const navigate = useNavigate();

    const [writingTextTitle, setWritingTextTitle] = useState();
    const [writingTextContainerAnimation, setWritingTextContainerAnimation] = useState();

    function shuffleArray(array) {
        const shuffled = [...array].sort(() => Math.random() - 0.5);
        const newArray = shuffled.map((element) => [element, 2500]).flat();
        return newArray;
    }

    const sequence = [
        800,
        ...shuffledTexts.flatMap(({title, texts}) => [
            () => {
                setWritingTextTitle(title);
                setWritingTextContainerAnimation("1s anim-show-text ease-out forwards");
            },
            1500,
            ...shuffleArray(texts),
            2500,
            () => {
                setWritingTextContainerAnimation("1s anim-hide-text forwards");
            },
            1250,
            "",
        ]),
    ];

    useEffect(() => {
        if (currentUser) {
            navigate("/chat");
        }
        getRedirectResult(auth).catch((error) => {
            if (
                error.code !== "auth/internal-error" &&
                error.status !== "INVALID_ARGUMENT"
            ) {
                toast.error(error.message);
            } else {
                toast.error("You Can Only Sign In With Valid BTU Mail");
            }
        });
    }, [currentUser, navigate]);

    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="login-container">
            <div className="login-ui-container">
                <div className="logo-container">
                    {/* <h1 style={{ color: "#ff66b2" }}>ClassroomAI</h1> */}
                    <img src={btuLogoWhite} about="BTU" alt="BTU"/>
                </div>
                <div
                    className="texts-container"
                    style={{animation: writingTextContainerAnimation}}
                >
                    <h1>{writingTextTitle}</h1>
                    <TypeAnimation
                        sequence={sequence}
                        wrapper="span"
                        cursor={false}
                        repeat={Infinity}
                        omitDeletionAnimation={true}
                        style={{
                            fontSize: "1.5em",
                            fontWeight: "normal",
                            display: "inline-block",
                            whiteSpace: "pre-line",
                            marginTop: "12px",
                        }}
                    />
                </div>
            </div>
            <div className="login-content-container">
                <div className="started-container">
                    <div className="started-content">
                        <h2>Get Started</h2>
                        <button className="sign-in-btn" onClick={handleGoogleSignIn}>
                            Sign In With BTU Gmail
                        </button>
                    </div>
                </div>
                <div className="login-bottomside-container">
                    {/*<h3>Powered By BTU</h3>*/}
                </div>

            </div>
        </div>
    );
}

export default Login;
