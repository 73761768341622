import {Navigate} from "react-router-dom";
import {UseAuth} from "../contexts/AuthContext";

function Protected({children}) {
    const {currentUser} = UseAuth();
    if (!currentUser) {
        return <Navigate to="/"/>;
    }
    return children;
}

export default Protected;
